import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md mt-5 mb-5">
      <h1 className="text-3xl font-bold mb-6 text-center">Privacy Policy</h1>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">User Agreement</h2>
        <p className="mb-4">Welcome to The Vin Report. By using our website (www.thevinreport.com) and our services, you agree to the following terms and conditions. If you do not agree with these terms, please do not use our services.</p>
        
        <h3 className="text-lg font-semibold mb-2">1. Services Provided</h3>
        <p className="mb-4">We provide comprehensive car history reports based on the Vehicle Identification Number (VIN) you provide. The information in these reports is sourced from various databases and is intended for personal use only.</p>

        <h3 className="text-lg font-semibold mb-2">2. Customer Obligations</h3>
        <p className="mb-4">You must provide accurate and complete information when ordering a car history report. We are not responsible for any delays or issues arising from incorrect information provided by you.</p>

        <h3 className="text-lg font-semibold mb-2">3. Payment</h3>
        <p className="mb-4">Payments are processed securely through Revolut. By making a purchase, you agree to our payment terms and authorize us to charge the amount indicated.</p>

        <h3 className="text-lg font-semibold mb-2">4. Refund Policy</h3>
        <p className="mb-4">Refunds are available if you contact us via email or our contact form with your concerns. We will review your request and determine if a refund is appropriate. All refund requests must be made within a reasonable time after the purchase.</p>

        <h3 className="text-lg font-semibold mb-2">5. Intellectual Property</h3>
        <p className="mb-4">All content on our website, including car history reports, is the intellectual property of The Vin Report. You may not reproduce, distribute, or create derivative works from our content without our express written permission.</p>

        <h3 className="text-lg font-semibold mb-2">6. Prohibited Activities</h3>
        <p className="mb-4">Piracy of our data is strictly prohibited. Unauthorized access, use, or distribution of our reports will result in legal action.</p>

        <h3 className="text-lg font-semibold mb-2">7. Changes to Terms</h3>
        <p className="mb-4">We reserve the right to update these terms at any time. Changes will be posted on our website, and it is your responsibility to review them periodically.</p>

        <h3 className="text-lg font-semibold mb-2">8. Contact and Support</h3>
        <p className="mb-4">We are available via live chat for any questions or support you may need. You can also reach us by email for any concerns.</p>

        <p>By using our services, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Refund Policy</h2>
        <p className="mb-4">At The Vin Report, customer satisfaction is our priority. If you are not satisfied with your car history report, you may request a refund under the following conditions:</p>

        <h3 className="text-lg font-semibold mb-2">1. How to Request a Refund</h3>
        <p className="mb-4">Contact us via email or our contact form with your concern. Provide your order details and the reason for your refund request.</p>

        <h3 className="text-lg font-semibold mb-2">2. Refund Eligibility</h3>
        <p className="mb-4">Refunds will be granted if there is a valid concern with the report provided, such as inaccuracies or missing information. Requests must be made within [X] days of purchase.</p>

        <h3 className="text-lg font-semibold mb-2">3. Refund Processing</h3>
        <p className="mb-4">Once we receive your request, we will review it and notify you of the approval or rejection of your refund. Approved refunds will be processed through your original payment method within [X] business days.</p>

        <h3 className="text-lg font-semibold mb-2">4. Non-Refundable Situations</h3>
        <p className="mb-4">Refunds are not available for delays caused by the customer’s failure to provide accurate or complete information. Refunds will not be issued for reports that have already been accessed and downloaded unless a valid concern is identified.</p>

        <p>We reserve the right to update our refund policy at any time.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Terms and Conditions</h2>
        <p className="mb-4">Welcome to www.thevinreport.com. By accessing and using our website, you agree to comply with the following terms and conditions:</p>

        <h3 className="text-lg font-semibold mb-2">1. Use of Website</h3>
        <p className="mb-4">You agree to use our website for lawful purposes only. You may not engage in any activity that interferes with the operation of our website or violates our intellectual property rights.</p>

        <h3 className="text-lg font-semibold mb-2">2. User Responsibilities</h3>
        <p className="mb-4">You are responsible for maintaining the confidentiality of your account information. You must provide accurate information when ordering a report, and you may not use the service for fraudulent or illegal purposes.</p>

        <h3 className="text-lg font-semibold mb-2">3. Limitation of Liability</h3>
        <p className="mb-4">We are not liable for any direct, indirect, incidental, or consequential damages arising from your use of our website or services. We do not guarantee the accuracy or completeness of the information in our reports.</p>

        <h3 className="text-lg font-semibold mb-2">4. Privacy Policy</h3>
        <p className="mb-4">Our Privacy Policy governs how we collect, use, and protect your personal information. By using our website, you agree to the terms of our Privacy Policy.</p>

        <h3 className="text-lg font-semibold mb-2">5. Changes to Terms</h3>
        <p className="mb-4">We may update these terms at any time without notice. It is your responsibility to review them regularly.</p>

        <h3 className="text-lg font-semibold mb-2">6. Governing Law</h3>
        <p className="mb-4">These terms and conditions are governed by and construed in accordance with the laws. By using our website, you acknowledge that you have read and agree to these terms and conditions.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Acknowledgement</h2>
        <p>Thank you for your purchase with The Vin Report. This is an acknowledgment that you have successfully ordered a car history report from our website.</p>

        <h3 className="text-lg font-semibold mb-2">Order Details:</h3>
        <ul className="list-disc ml-6 mb-4">
          <li>Order Number: [Your Order Number]</li>
          <li>Date of Purchase: [Date]</li>
          <li>Amount: [Amount]</li>
          <li>Payment Method: Revolut</li>
        </ul>

        <p>By completing this purchase, you agree to our User Agreement, Refund Policy, and Terms and Conditions. Please review these documents if you have not already done so.</p>
        <p>If you have any questions or need assistance, please contact us via live chat or email.</p>
        <p>Thank you for choosing The Vin Report.</p>
      </section>
    </div>
  )
}

export default PrivacyPolicy