import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Loader from './Loader'; // Ensure you have a Loader component

const CheckoutForm = ({orderData,setModalLoader}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loader, setLoader] = useState(false); // State for loader visibility

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Start loader
    setLoader(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error(error);
      setLoader(false); // Stop loader
    } else {
      // Send paymentMethod.id to your server
      //https://backend.thevinreport.com/api/stripe.php
      const response = await fetch('https://backend.thevinreport.com/api/stripe.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          amount: 1 * 100, // Adjust amount accordingly
          name: orderData?.customer_name,
          phone: orderData?.customer_phone,
          vin: orderData?.customer_VIN,
          email: orderData?.customer_email
        }),
      });

      const result = await response.json();
      setLoader(false); // Stop loader

      if (result.error) {
        console.error(result.error);
      } else {
        console.log('Payment succeeded:', result);
        setModalLoader(2)
        // You can also redirect or show a success message here
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-6 border border-gray-300 rounded-lg shadow-md bg-white">
      <h2 className="text-xl font-semibold mb-4 text-center">Payment Information</h2>
      <p className="mb-4 text-gray-600 text-sm text-center">
        Please enter your payment information below. Your card details will be securely processed and will not be stored on our servers.
      </p>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">Card Details</label>
        <CardElement className="border rounded p-2" options={{
          iconStyle: 'solid',
          style: {
            base: {
              iconColor: 'black',
              color: 'black',
              fontWeight: 300,
              fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
              fontSize: '21px',
              fontSmoothing: 'antialiased',
              ':-webkit-autofill': {
                color: '#fce883',
              },
              '::placeholder': {
                color: 'lightgrey',
              },
            },
            invalid: {
              iconColor: 'red',
              color: 'red',
            },
          },
        }} />
      </div>
      <button
        type="submit"
        disabled={!stripe}
        className={`w-full py-2 mt-4 text-white font-semibold rounded ${stripe ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'}`}
      >
        <Loader isLoading={loader} /> {loader ? "" : `Pay $${orderData?.package}`}

      </button>
    </form>
  );
};

export default CheckoutForm;
